import React, { useRef, useState, useEffect, useContext } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import { Flex, Image } from "@tempo/core";
import _noop from "lodash/noop";
import buildConfig from "build-config.json";

const LoadingScreenContext =
  React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(_noop);

type Props = {
  children: React.ReactNode;
};

export const LoadingScreenProvider: React.FC<Props> = ({ children }) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [nextIsLoading, setNextIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!nextIsLoading) {
      timeoutRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 350);
    } else if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setIsLoading(true);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [nextIsLoading]);

  return (
    <LoadingScreenContext.Provider value={setNextIsLoading}>
      {isLoading && <LoadingScreen />}
      {children}
    </LoadingScreenContext.Provider>
  );
};

export const useLoadingScreen = ({ isLoading }: { isLoading: boolean }) => {
  const setIsLoading = useContext(LoadingScreenContext);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [setIsLoading, isLoading]);

  return isLoading;
};

function LoadingScreen() {
  const theme = useTheme();

  return (
    <Container w="full" h="full" alignItems="center" justifyContent="center">
      <PulsingImage
        objectFit="contain"
        src={
          theme.name === "light"
            ? `${buildConfig.host_assets_ssl}/images/deezer-logo-dark.svg`
            : `${buildConfig.host_assets_ssl}/images/deezer-logo-light.svg`
        }
        alt="deezer-logo"
        w="32"
      />
    </Container>
  );
}

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

const PulsingImage = styled(Image)`
  animation: ${pulse} 4s ease infinite;
`;

const Container = styled(Flex)`
  position: absolute;
  z-index: 10;

  background-color: ${({ theme }) => theme.colors.background};
  ${({ theme }) =>
    theme.playerRadius &&
    `
      border-radius: ${theme.borderRadius[10]};
    `}
`;

export default LoadingScreen;
